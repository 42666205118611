import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useUploadRequireFilesMutation } from "../redux/features/files/filesApi";
import ProvideIdCard from "../components/dashboardsfiles/User/UserUi/ProvideIdCard";
import ProvidePassport from "../components/dashboardsfiles/User/UserUi/ProvidePassport";
import ProvidePPSizePhoto from "../components/dashboardsfiles/User/UserUi/ProvidePPSizePhoto";
import toast from "react-hot-toast";
import ProvideTradeLicense from "../components/dashboardsfiles/User/UserUi/ProvideTradeLicense";
import { MdOutlineDoubleArrow } from "react-icons/md";

const getSteps = (activeStep) => [
  <div key="nid-card" className="">
    <div className="position-relative">NID Card</div>
  </div>,
  <div key="pp-size-photo" className="">
    <div className="position-relative">
      Passport Size Photo
      {activeStep >= 1 && (
        <MdOutlineDoubleArrow className="position-absolute step-arrow" />
      )}
    </div>
  </div>,
  <div key="passport" className="">
    <div className="position-relative">
      Passport
      {activeStep >= 2 && (
        <MdOutlineDoubleArrow className="position-absolute step-arrow" />
      )}
    </div>
  </div>,
  <div key="trade-license" className="">
    <div className="position-relative">
      Trade License
      {activeStep >= 3 && (
        <MdOutlineDoubleArrow className="position-absolute step-arrow" />
      )}
    </div>
  </div>,
];

export default function Timeline({ user, activeCompanyId }) {
  const [uploadRequireFiles, { isLoading: isUploading }] =
    useUploadRequireFilesMutation();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const [nid, setNid] = React.useState(null);
  const [ppSizePhoto, setPPSizePhoto] = React.useState(null);
  const [passport, setPassport] = React.useState(null);
  const [tradeLicense, setTradeLicense] = React.useState(null);

  const files = [nid, ppSizePhoto, passport];
  if (tradeLicense) {
    files.push(tradeLicense);
  }

  const isStepOptional = (step) => step === 3;

  const isStepSkipped = (step) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () =>
    setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
    setNid(null);
    setPPSizePhoto(null);
    setPassport(null);
    setTradeLicense(null);
  };

  
  const handleFileUpload = async () => {
    let skippedFiles = [];
    files.forEach((file) => {
      if (!file) {
        skippedFiles.push(file);
        return;
      }
    });

    if (skippedFiles.length > 0) {
      toast.error("Some files are null. Skipping upload.");
    }
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });

    const folderName = `UserData/${user?.email}/documents/companyData/${activeCompanyId}/providedDocuments/`;

    try {
      const result = await uploadRequireFiles({
        formData,
        folderName,
      }).unwrap();
      if (result?.success === true) {
        toast.success("Files uploaded successfully", {
          duration: 2500,
          style: { background: "#050F26" },
          className: "text-white",
        });

        setNid(null);
        setPPSizePhoto(null);
        setPassport(null);
        setTradeLicense(null);
      } else {
        toast.error("Failed to upload files");
      }
    } catch (error) {
      toast.error("Error uploading files");
      console.error("Error uploading files:", error);
    }
  };

  const renderStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <ProvideIdCard nid={nid} setNid={setNid} />;
      case 1:
        return (
          <ProvidePPSizePhoto
            ppSizePhoto={ppSizePhoto}
            setPPSizePhoto={setPPSizePhoto}
          />
        );
      case 2:
        return (
          <ProvidePassport passport={passport} setPassport={setPassport} />
        );
      case 3:
        return (
          <ProvideTradeLicense
            setTradeLicense={setTradeLicense}
            tradeLicense={tradeLicense}
          />
        );
      default:
        return "Unknown step"; // This will be displayed if stepIndex is out of range
    }
  };

  const isNextButtonDisabled = () => {
    switch (activeStep) {
      case 0:
        return !nid;
      case 1:
        return !ppSizePhoto;
      case 2:
        return !passport;
      case 3:
        return false;
      default:
        return true;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {getSteps(activeStep).map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label.key} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === getSteps(activeStep).length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            {isUploading ? (
              <div className="upload-custom-button">
                Uploading please wait...
              </div>
            ) : (
              <div onClick={handleFileUpload} className="upload-custom-button">
                Upload All Files
              </div>
            )}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {renderStepContent(activeStep)}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}
            <Button onClick={handleNext} disabled={isNextButtonDisabled()}>
              {activeStep === getSteps(activeStep).length - 1
                ? "Finish"
                : "Next"}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
