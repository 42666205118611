import React from "react";
import { useGetEMIDetailsByTransactionIdQuery } from "../../../../redux/features/admin/adminApi";
import { TbCoinTakaFilled } from "react-icons/tb";
import { MdCalendarMonth } from "react-icons/md";

export default function EmiDetails({ transaction_id }) {
  const { data: emiDetailsInfo } =
    useGetEMIDetailsByTransactionIdQuery(transaction_id);
  const emiDetails = emiDetailsInfo?.data || {};
  const {
    emi_amount,
    paidInstallments,
    totalInstallments,
    paidBalance,
    remainingBalance,
    status,
    createdAt,
    nextInstallmentDate,
    total_amount,
  } = emiDetails;

  const startDate = new Date(createdAt);
  const nextInstallment = new Date(nextInstallmentDate);
  const lastInstallmentDate = new Date(startDate);
  lastInstallmentDate.setMonth(
    lastInstallmentDate.getMonth() + (paidInstallments - 1)
  );

  const installments = Array.from({ length: totalInstallments }, (_, index) => {
    const installmentNumber = index + 1;
    const installmentMonth = new Date(startDate).setMonth(
      startDate.getMonth() + index
    );
    const isPaid = installmentNumber <= paidInstallments;
    const isPending = !isPaid && installmentNumber === paidInstallments + 1;

    return {
      installmentNumber,
      amount: emi_amount,
      month: new Intl.DateTimeFormat("en-US", {
        month: "long",
        year: "numeric",
      }).format(installmentMonth),
      date: new Date(installmentMonth).toLocaleDateString(),
      status: isPaid ? "Paid" : isPending ? "Pending" : "Upcoming",
    };
  });

  return (
    <div className=" mx-4">
      <section className="d-md-flex gap-4">
        <section className="billing-width-fixing-50 card emi-card my-md-0 my-3">
          <div className="table-responsive emi-table">
            <table className="emi-custom-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {installments.map((installment) => (
                  <tr
                    key={installment.installmentNumber}
                    className={
                      installment.status === "Paid"
                        ? "paid-row"
                        : installment.status === "Pending"
                        ? "pending-row"
                        : "upcoming-row"
                    }
                  >
                    <td>{installment.installmentNumber}</td>

                    <td className="d-flex gap-1 align-items-center  ">
                      <MdCalendarMonth />
                      {installment.month}
                    </td>
                    <td>{installment.date}</td>
                    <td>
                      {installment.status}{" "}
                      {installment.status === "Paid" && (
                        <span className="text-success">✔</span>
                      )}
                      {installment.status === "Pending" && (
                        <span className="text-warning">⚠</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
        <transactionCard className="billing-width-fixing-50">
          <div className="rating-card2 d-flex justify-content-center">
            <div
              style={{ fontSize: "17px" }}
              className="text-primary-tr text-center "
            >
              EMI Details Information
            </div>
          </div>
          <div className="rating-card3 text-center mt-3 position-relative">
            <div className=" ">
              <div className="text-primary-tr ">EMI Amount</div>
              <div style={{ fontSize: "18px" }} className="text-white ">
                {emi_amount ? emi_amount.toFixed(2) : "0.00"}
              </div>
            </div>
            <div className="">
              <div className="verticalLine"></div>
            </div>
            <div className=" ">
              <div className="text-primary-tr ">Total Installments</div>
              <div style={{ fontSize: "18px" }} className="text-white ">
                {totalInstallments}
              </div>
            </div>
            <div>
              <div className="verticalLine"></div>
            </div>
            <div className=" ">
              <div className="text-primary-tr ">Paid Installments</div>
              <div style={{ fontSize: "18px" }} className="text-white ">
                {paidInstallments}
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 mt-3">
            <transactionCard className="w-50 border border-1 h-100 ">
              <div className="rating-card">
                <div className="text-wrapper-tr">
                  <div style={{ fontSize: "17px" }} className="text-primary-tr">
                    Balance Information
                  </div>
                </div>
                <div className="socials-container ">
                  <div className="text-primary-tr ">Total Amount</div>
                  <div style={{ fontSize: "12px" }} className="text-white ">
                    {total_amount}
                  </div>
                </div>
                <div className="socials-container ">
                  <div className="text-primary-tr ">Paid Amount</div>
                  <div style={{ fontSize: "12px" }} className="text-white ">
                    {paidBalance ? paidBalance.toFixed() : "N/A"}
                  </div>
                </div>
                <div className="socials-container ">
                  <div className="text-primary-tr ">Remaining Amount </div>
                  <div style={{ fontSize: "12px" }} className="text-white ">
                    {remainingBalance ? remainingBalance.toFixed(2) : "N/A"}
                  </div>
                </div>
              </div>
            </transactionCard>
            <transactionCard className="w-50 border border-1 h-100 ">
              <div className="rating-card">
                <div className="text-wrapper-tr">
                  <div style={{ fontSize: "17px" }} className="text-primary-tr">
                    Status Check
                  </div>
                </div>
                <div className="socials-container ">
                  <div className="text-primary-tr ">Status</div>
                  <div style={{ fontSize: "12px" }} className="text-white ">
                    {status}
                  </div>
                </div>
                <div className="socials-container">
                  <div className="text-primary-tr">Last Installment</div>
                  <div style={{ fontSize: "12px" }} className="text-white">
                    {lastInstallmentDate.toLocaleDateString()}
                  </div>
                </div>
                {paidInstallments < totalInstallments && (
                  <div className="socials-container">
                    <div className="text-primary-tr">Next Installment</div>
                    <div style={{ fontSize: "12px" }} className="text-white">
                      {nextInstallment.toLocaleDateString()}
                    </div>
                  </div>
                )}
              </div>
            </transactionCard>
          </div>
        </transactionCard>
      </section>
    </div>
  );
}
